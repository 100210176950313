import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { useStoreContext } from "../utils/GlobalState";
import {
  UPDATE_CART_QUANTITY,
  ADD_TO_CART,
  UPDATE_PRODUCTS,
} from "../utils/actions";
import { QUERY_PRODUCTS } from "../utils/queries";
import { idbPromise } from "../utils/helpers";
import Footer from "../components/Footer";
import Redirector from "../utils/redirector";
import useScrollHelper from "../utils/scrollhelper";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Cart from "../components/Cart";

function Detail() {
  Redirector.checkTokens();
  useScrollHelper();

  const [state, dispatch] = useStoreContext();
  const { id } = useParams();

  const [currentProduct, setCurrentProduct] = useState({});
  const [selectedVariant, setSelectedVariant] = useState("");

  const { loading, data } = useQuery(QUERY_PRODUCTS);

  const { products, cart } = state;

  useEffect(() => {
    if (products.length) {
      setCurrentProduct(products.find((product) => product._id === id));
    } else if (data) {
      dispatch({
        type: UPDATE_PRODUCTS,
        products: data.products,
      });

      data.products.forEach((product) => {
        idbPromise("products", "put", product);
      });
    } else if (!loading) {
      idbPromise("products", "get").then((indexedProducts) => {
        dispatch({
          type: UPDATE_PRODUCTS,
          products: indexedProducts,
        });
      });
    }
  }, [products, data, loading, dispatch, id]);

  const addToCart = (selectedVariantId) => {
    const selectedVariantDetails = currentProduct.variant.find(
      (v) => v._id === selectedVariantId
    );

    const itemInCart = cart.find(
      (cartItem) =>
        cartItem._id === `${currentProduct._id}_${selectedVariantId}`
    );

    if (itemInCart) {
      dispatch({
        type: UPDATE_CART_QUANTITY,
        _id: `${currentProduct._id}_${itemInCart.variant?._id}`,
        purchaseQuantity: parseInt(itemInCart.purchaseQuantity) + 1,
      });
      idbPromise("cart", "put", {
        ...itemInCart,
        _id: `${currentProduct._id}_${itemInCart.variant?._id}`,
        purchaseQuantity: parseInt(itemInCart.purchaseQuantity),
      });
    } else {
      dispatch({
        type: ADD_TO_CART,
        product: {
          ...currentProduct,
          _id: `${currentProduct._id}_${selectedVariantId}`,
          purchaseQuantity: 1,
          variant: selectedVariantDetails,
        },
      });
      idbPromise("cart", "put", {
        ...currentProduct,
        _id: `${currentProduct._id}_${selectedVariantId}`,
        purchaseQuantity: 1,
        variant: selectedVariantDetails,
      });
    }
  };

  const ProductDropdown = () => {
    const { variant } = currentProduct;

    if (!variant || variant.length === 0) {
      return null;
    }

    const handleVariantChange = (event) => {
      const selected = event.target.value;
      setSelectedVariant(selected);
    };

    return (
      <select
        onChange={handleVariantChange}
        value={selectedVariant}
        key={selectedVariant}
        className="w-full h-10 p-2 border rounded-md"
      >
        <option value="">Select flavor</option>
        {variant.map((vari, index) => (
          <option key={index} value={vari._id}>
            {vari.name}
          </option>
        ))}
      </select>
    );
  };

  return (
    <>
      <div className="mt-20">
        <button
          onClick={() => window.history.back()}
          className="hover:underline text-white"
        >
          ← Back to Previous Page
        </button>
      </div>

      {currentProduct && cart ? (
        <section className="w-full py-12 md:py-10 lg:py-10">
          <div className="container flex flex-col-reverse items-start md:flex-row gap-8 px-4 md:px-6">
            <div className="w-full md:w-1/2">
              {currentProduct.image?.split(";").length > 1 ? (
                <Carousel showArrows={true} infiniteLoop={true}>
                  {currentProduct.image
                    ?.split(";")
                    .map((slideProduct, index) => (
                      <div key={index}>
                        <img
                          src={`${slideProduct}`}
                          alt={`Product ${index + 1}`}
                          className="w-full h-auto object-cover"
                          style={{ maxHeight: 500 }}
                        />
                      </div>
                    ))}
                </Carousel>
              ) : (
                <img
                  src={`${currentProduct.image}`}
                  alt={currentProduct.name}
                  className="w-full h-auto object-cover"
                  style={{ maxHeight: 500 }}
                />
              )}
            </div>
            <div className="w-full md:w-1/2 space-y-6">
              <h2 className="text-4xl font-bold tracking-tighter">
                {currentProduct.name}
              </h2>

              <p className="text-2xl font-semibold text-zinc-900 dark:text-zinc-50">
                ${currentProduct.price}
              </p>
              <p
                className="text-base text-zinc-500 dark:text-zinc-400"
                dangerouslySetInnerHTML={{
                  __html: currentProduct.description,
                }}
              />
              <div className="flex flex-col md:flex-row items-start md:items-center space-y-2 md:space-x-2 md:space-y-0">
                {currentProduct.variant?.length > 0 && <ProductDropdown />}
                <button
                  className="w-full h-12 mt-2 md:mt-0 text-white rounded-md bg-gradient-to-r from-red-400 to-yellow-600 opacity-80 text-zinc-50 shadow-sm dark:bg-zinc-50 dark:text-zinc-900"
                  onClick={() => addToCart(selectedVariant)}
                  disabled={
                    currentProduct.variant?.length > 0 && !selectedVariant
                  }
                >
                  Add to Cart
                </button>
              </div>
            </div>
          </div>
        </section>
      ) : null}

      {loading ? (
        <div className="text-center mt-40">
          <div className="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      ) : null}
      <Cart />
      <Footer />
    </>
  );
}

export default Detail;
