import React from "react";

const OptimizedImage = ({ src, alt }) => (
  <img
    style={{ height: 200, width: 260 }}
    src={src}
    loading="lazy"
    height={100}
    width={100}
    alt={alt}
  />
);

export default OptimizedImage;
