import React, { useState } from "react";
import { Link } from "react-router-dom";
import { pluralize } from "../../utils/helpers";
import { useStoreContext } from "../../utils/GlobalState";
import { ADD_TO_CART, UPDATE_CART_QUANTITY } from "../../utils/actions";
import { idbPromise } from "../../utils/helpers";
import Auth from "../../utils/auth";
import { useMutation } from "@apollo/client";
import { DEL_PRODUCT } from "../../utils/mutations";
import AdminUpdateForm from "../AdminUpdate";
import OptimizedImage from "../ui/image/image";

const ProductItem = (item) => {
  const [displayModal, setDisplayModal] = useState();

  const [delProduct] = useMutation(DEL_PRODUCT);

  const [state, dispatch] = useStoreContext();

  const {
    image,
    name,
    _id,
    price,
    quantity,
    description,
    cloverId,
    variant,
    category,
  } = item;

  const { cart } = state;

  const addToCart = () => {
    const itemInCart = cart.find((cartItem) => cartItem._id === _id);
    if (itemInCart) {
      dispatch({
        type: UPDATE_CART_QUANTITY,
        _id: _id,
        purchaseQuantity: parseInt(itemInCart.purchaseQuantity) + 1,
      });

      idbPromise("cart", "put", {
        ...itemInCart,
        purchaseQuantity: parseInt(itemInCart.purchaseQuantity) + 1,
      });
    } else {
      dispatch({
        type: ADD_TO_CART,
        product: { ...item, purchaseQuantity: 1 },
      });
      idbPromise("cart", "put", { ...item, purchaseQuantity: 1 });
    }
  };

  const handleDelete = async (id) => {
    if (
      window.confirm(
        "Deleting this product deletes it everywhere. Are you sure you want to delete this product?"
      )
    ) {
      try {
        await delProduct({
          variables: { id },
        });

        window.location.reload();
      } catch (err) {
        console.log(err);
      }
    }
  };

  if (Auth.isAdmin()) {
    return (
      <div className="card px-1 py-1">
        <Link to={`/products/${_id}`}>
          <img
            style={{ height: 230, width: 240 }}
            alt={name}
            src={`${image}`}
          />
          <p className="text-white">{name}</p>
        </Link>
        <div>
          <div>
            {quantity} {pluralize("item", quantity)} in stock
          </div>
          <span>${price}</span>
        </div>

        <button
          className="bg-red-400 text-white rounded "
          onClick={() => {
            handleDelete(_id);
          }}
        >
          Delete
        </button>

        <button
          className="bg-gradient-to-r from-red-400 to-yellow-600 opacity-80 text-white rounded"
          onClick={() => {
            setDisplayModal(true);
          }}
        >
          Update
        </button>

        {displayModal ? (
          <AdminUpdateForm
            displayModal={setDisplayModal}
            id={_id}
            name={name}
            cloverId={cloverId}
            category={category}
            description={description}
            image={image}
            price={price}
            quantity={quantity}
            variant={variant}
          />
        ) : (
          <></>
        )}
      </div>
    );
  } else {
    return (
      <>
        <div className="card px-10 mx-1 py-1 borderwrap bg-white bg-opacity-10">
          <Link to={`/products/${_id}`}>
            <OptimizedImage alt={name} src={`${image}`} />
            <p style={{ maxWidth: 280 }}>{name}</p>
          </Link>
          <div>
            <div>
              {quantity < 5 && Auth.isAdmin() !== true ? (
                <>
                  {" "}
                  {quantity} {pluralize("item", quantity)} in stock
                </>
              ) : (
                <>
                  {Auth.isAdmin() === true ? (
                    <>
                      {" "}
                      {quantity} {pluralize("item", quantity)} in stock
                    </>
                  ) : null}
                </>
              )}
            </div>
            <span>${price}</span>
          </div>
        </div>
      </>
    );
  }
};

export default ProductItem;
